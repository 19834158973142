import React from 'react'
import parse, { domToReact } from 'html-react-parser'
import { CSVLink } from 'react-csv'
import Link from '@atoms/link'
import CsvToHtmlTable from '@molecules/csv-to-html'

const options = {
  replace: ({ name, attribs, children }) => {
    if (name === 'a') {
      return (
        <Link to={attribs.href} newtab>
          {domToReact(children, options)}
        </Link>
      )
    }
    if (
      attribs?.class === 'csv-data' &&
      children?.length > 0 &&
      typeof window !== 'undefined'
    ) {
      return (
        <>
          <CsvToHtmlTable data={children[0].data} csvDelimiter={','} />
          <CSVLink
            filename="metricHQ-data.csv"
            style={{
              fontSize: '1.1rem',
              background: '#00805D',
              display: 'inline-block',
              marginTop: '1.5rem',
              color: 'white',
              textDecoration: 'none',
              padding: '0.5rem 1rem',
              borderRadius: '1.5rem'
            }}
            data={children[0].data}
          >
            Download CSV
          </CSVLink>
        </>
      )
    }
    return
  }
}

export const parseContent = (content, example) => {
  content = content.replace(
    /Klipfolio PowerMetrics/gim,
    `<a href="https://klipfolio.com/" target="_blank">Klipfolio PowerMetrics</a>`
  )
  if (example) {
    if (example.name && example.url) {
      content = content.replace(
        new RegExp(`(${example.name})`, 'gmi'),
        `<a href="${example.url}" target="_blank">$1</a>`
      )
      if (example.services) {
        for (const service of example.services) {
          content = content.replace(
            new RegExp(`(${service.name})`, 'gmi'),
            `<a href="${service.slug}" target="_blank">$1</a>`
          )
        }
      }
    }
  }
  content = content.replace(/```(.*)```/gms, `<div class="csv-data">$1</div>`)

  return parse(content, options)
}
