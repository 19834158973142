import React from 'react'
import { parseCsvToRowsAndColumn } from './utils'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const TableWrapper = styled.div`
  border-radius: 1rem;
  overflow-x: scroll;
  box-shadow: 0px 128px 128px 0px rgb(33 38 79 / 20%),
    0px 16px 16px 0px rgb(33 38 79 / 15%), 0px 8px 8px 0px rgb(33 38 79 / 10%);
  max-width: 100%;
  width: 100%;

  table {
    border-collapse: collapse;
    width: 100%;

    th,
    td {
      white-space: nowrap;
    }
  }
`

const CsvToHtmlTable = ({
  data,
  csvDelimiter,
  hasHeader,
  tableClassName,
  tableRowClassName,
  tableColumnClassName,
  rowKey,
  colKey,
  renderCell
}) => {
  const rowsWithColumns = parseCsvToRowsAndColumn(data.trim(), csvDelimiter)
  let headerRow = undefined
  if (hasHeader) {
    headerRow = rowsWithColumns.splice(0, 1)[0]
  }

  const renderTableHeader = (row) => {
    if (row && row.map) {
      return (
        <thead>
          <tr>
            {row.map((column, i) => (
              <th key={`header-${i}`}>{column}</th>
            ))}
          </tr>
        </thead>
      )
    }
  }

  const renderTableBody = (rows) => {
    if (rows && rows.map) {
      return (
        <tbody>
          {rows.map((row, rowIdx) => (
            <tr
              className={tableRowClassName}
              key={typeof rowKey === 'function' ? rowKey(row, rowIdx) : rowIdx}
            >
              {row.map &&
                row.map((column, colIdx) => (
                  <td
                    className={tableColumnClassName}
                    key={
                      typeof rowKey === 'function'
                        ? colKey(row, colIdx, rowIdx)
                        : column[colKey]
                    }
                  >
                    {typeof renderCell === 'function'
                      ? renderCell(column, colIdx, rowIdx)
                      : column}
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      )
    }
  }

  return (
    <TableWrapper>
      <table className={`csv-html-table ${tableClassName}`}>
        {renderTableHeader(headerRow)}
        {renderTableBody(rowsWithColumns)}
      </table>
    </TableWrapper>
  )
}

CsvToHtmlTable.propTypes = {
  data: PropTypes.string,
  csvDelimiter: PropTypes.string,
  hasHeader: PropTypes.bool,
  tableClassName: PropTypes.string,
  tableRowClassName: PropTypes.string,
  tableColumnClassName: PropTypes.string,
  rowKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  colKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  renderCell: PropTypes.func
};

CsvToHtmlTable.defaultProps = {
  data: '',
  rowKey: (rowIdx) => `row-${rowIdx}`,
  colKey: (colIdx) => `col-${colIdx}`,
  hasHeader: true,
  csvDelimiter: '\t',
  tableClassName: '',
  tableRowClassName: '',
  tableColumnClassName: ''
}

export default CsvToHtmlTable
