import React, { useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Layout from '@organisms/layout'
import styled from 'styled-components'
import Heading from '@particles/heading'
import Text from '@particles/text'
import SEO from '@atoms/seo'
import hqLogo from '@src/images/hq-logo.svg'
import { AnimatePresence, motion } from 'framer-motion'
import Icon from '@icons'
import algoliasearch from 'algoliasearch'
import { graphql, useStaticQuery } from 'gatsby'
import Line from '@particles/line'
import Tags from '@molecules/tags'
import Formula from '@atoms/formula'
import ServiceLogo from '@atoms/service-logo'
import Link from '@atoms/link'
import '@styles/table.css'
import { media, useMedia } from '@breakpoints'
import { parseContent } from '../utils/ai-utils'

const algoliaClient = algoliasearch(
  'U276KG3JBP',
  'cf30bf2b0d0511db02921358e65eeeaa',
  { _useRequestCache: true }
)

const Wrapper = styled.div`
  background: linear-gradient(180deg, #181c26 0%, #3b455e 100%);
  height: calc(100vh - 60px);
  display: flex;
  ${media.desktop`
    align-items: center;
  `}
`

const Container = styled.div`
  max-width: ${({ theme }) => theme.default.container}px;
  padding: 4rem 2rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;

  ${media.desktop`
    grid-template-rows: min-content min-content;
    height: auto;
    grid-template-columns: none;
    justify-content: initial;
  `}
`

const Orb = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 400px;
  background: radial-gradient(50% 50% at 50% 50%, #ffffff 0%, #34ad78 100%);
  border-radius: 50%;
`

const OrbWrapper = styled.div`
  border-radius: 50%;
  width: min-content;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    border-radius: 50%;
    box-shadow: 0 0 300px rgba(117, 225, 178, 0.5);
    animation: shadowFade 6s ease-in-out infinite;
  }

  @keyframes shadowFade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  ${media.desktop`
    margin: 0 auto;
  `}
`

const ChatIcon = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.light ? '#e8fde2' : '#75e1b2')};
  box-shadow: 0 0 24px rgba(1, 75, 51, 0.25);
  border-radius: 50%;
  img {
    width: 30px;
  }

  ${media.desktop`
    width: 40px;
    height: 40px;

    img {
      width: 20px;
    }
  `}
`

const CenterOrb = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60%;
  width: 60%;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  animation: breatheCenter 6s ease-in-out infinite;

  @keyframes breatheCenter {
    0% {
      box-shadow: 0;
    }
    50% {
      box-shadow: inset 0 0 120px 15px rgba(117, 225, 178, 0.5);
    }
    100% {
      box-shadow: 0;
    }
  }
`

const ChatText = styled(Text)`
  font-size: 1.2rem;

  ${media.desktop`
    font-size: 1.1rem;
  `}
`

const SubHeading = styled(Text)`
  font-size: 1.3rem;
  max-width: 800px;

  ${media.desktop`
    font-size: 1.1rem;
    max-width: 600px;
  `}
`

const SearchBarWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  position: relative;
`

const SearchBar = styled.input`
  border-radius: 2rem;
  font-size: 1.3rem;
  font-weight: 600;
  width: 100%;
  padding: 1rem 1rem 1rem 1.5rem;
  background: white;
  box-shadow: 0px 0px 64px rgba(117, 225, 178, 0.25);
  border: none;
  transition: box-shadow 1s ease-in-out, outline 0.3s ease-in-out;
  outline: 0px solid rgba(52, 173, 120, 1);

  ${media.desktop`
    font-size: 1.1rem;
  `}

  &:focus {
    outline: 2px solid rgba(52, 173, 120, 1);
    box-shadow: 0px 0px 128px rgba(117, 225, 178, 0.5);
  }
`

const ChatLayout = styled(Layout)`
  main {
    max-width: none;
    padding: 0;
    margin-bottom: -4rem;
    display: flex;
    flex-direction: column;
  }
`

const textVariants = {
  show: { opacity: 1, height: 'auto' },
  hide: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.4
    }
  }
}

const LoadContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  display: inline-block;
  width: 3rem;
  height: 2rem;
  overflow: hidden;
  letter-spacing: normal;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`

const ChatHeader = styled(Heading)`
  margin-bottom: 1.5rem;
  max-width: 650px;

  ${media.desktop`
    max-width: 600px;
  `}
`

const Spinner = styled.div`
  width: 4px;
  height: 16px;
  background: #007f5d;
  position: relative;
  animation: linespinner 1.5s ease-in-out infinite;
  animation-delay: 0.4s;
  border-radius: 8px;

  &:after,
  &:before {
    border-radius: 8px;
    content: '';
    position: absolute;
    width: 4px;
    height: 16px;
    background: #007f5d;
    animation: linespinner 1.5s ease-in-out infinite;
  }

  &:before {
    right: 12px;
    animation-delay: 0.225s;
  }

  &:after {
    left: 12px;
    animation-delay: 0.9s;
  }

  @keyframes linespinner {
    0%,
    100% {
      background-color: #34ad78;
      box-shadow: 0 0 0 #34ad78, 0 0 0 #34ad78;
    }
    50% {
      background-color: #007f5d;
      box-shadow: 0 -4px 0 #007f5d, 0 4px 0 #007f5d;
    }
  }
`

const ChatWrapper = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 60px 1fr;

  ${media.desktop`
    display: flex;
    flex-flow: column;
    gap: 1.5rem;
  `}
`

const OrbContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  ${media.desktop`
    order: -1;
  `}
`

const wrapperVariants = {
  show: { height: 'calc(100vh - 60px)' },
  hide: {
    height: 'auto',
    transition: {
      duration: 0.5
    }
  }
}

const spinnerVariants = {
  hide: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.5
    }
  }
}

const Content = styled.div`
  max-width: 900px;
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-flow: column;
  gap: 2rem;
  padding: ${({ padding }) => padding && '2rem 0'};

  ${media.desktop`
    padding: ${({ padding }) => padding && '2rem'};
    max-width: calc(600px + 4rem);
  `};
`

const logoVariants = {
  initial: {
    rotate: 0,
    transition: {
      duration: 1
    }
  },
  loading: {
    rotate: 360,
    ease: 'circInOut',
    transition: {
      duration: 2,
      repeat: Infinity
    }
  }
}

const TextContainer = styled.div`
  opacity: 0;
  ${media.desktop`
    margin: 0 auto;
  `}
`

const ChatBox = ({ padding, children }) => {
  return (
    <Content padding={padding}>
      <AnimatePresence>{children}</AnimatePresence>
    </Content>
  )
}

ChatBox.propTypes = {
  padding: PropTypes.bool,
  children: PropTypes.node
}

const ChatMessageResponse = ({ role, children, example }) => {
  return (
    <ChatWrapper
      as={motion.div}
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: 'auto', opacity: 1 }}
      transition={{
        delay: role === 'user' ? 0 : 0.3,
        duration: 0.3
      }}
    >
      <ChatIcon light={role === 'user' ? false : true}>
        {role === 'user' ? (
          <Icon name={'user'} size={2} />
        ) : (
          <img
            style={{
              filter: 'drop-shadow(0px 0px 36px rgba(0, 51, 34, 0.4)'
            }}
            src={hqLogo}
            alt="MetricHQ AI ChatBot by Klipfolio"
          />
        )}
      </ChatIcon>
      {role === 'user' ? (
        <Heading h4 style={{ alignSelf: 'center' }}>
          {children}
        </Heading>
      ) : (
        <div>
          <ChatText>{children}</ChatText>
          {example && example.type === 'metric' && (
            <div
              style={{
                padding: '2rem',
                background: 'white',
                borderRadius: '1rem',
                marginTop: '2rem',
                boxShadow:
                  '0px 128px 128px 0px rgb(33 38 79 / 20%), 0px 16px 16px 0px rgb(33 38 79 / 15%), 0px 8px 8px 0px rgb(33 38 79 / 10%)'
              }}
            >
              <ChatText margin="B2">
                Learn more about&nbsp;
                <Link style={{ fontWeight: '700' }} to={example.url}>
                  {example.name}
                </Link>
              </ChatText>
              {example.formula?.map(({ formula }, i) => (
                <Layout.Column key={`formula-${i}`} style={{ width: '100%' }}>
                  <Text as="span" size={0.7} uppercase spacing={0.05} bold>
                    Formula
                  </Text>
                  <Line style={{ width: '100%' }} margin="T0.25 B1" />
                  <Formula style={{ marginBottom: '2rem' }}>{formula}</Formula>
                </Layout.Column>
              ))}
              {example.roles?.length > 0 && (
                <Layout.Column style={{ width: '100%' }}>
                  <Text as="span" size={0.7} uppercase spacing={0.05} bold>
                    Roles
                  </Text>
                  <Line style={{ width: '100%' }} margin="T0.25 B1" />
                  <Tags
                    wrap="left"
                    solid="true"
                    tags={example.roles.map((role) => ({
                      name: role.name,
                      link: role.url
                    }))}
                  />
                </Layout.Column>
              )}
              {example.categories?.length > 0 && (
                <Layout.Column style={{ width: '100%' }} margin="T2">
                  <Text as="span" size={0.7} uppercase spacing={0.05} bold>
                    Categories
                  </Text>
                  <Line style={{ width: '100%' }} margin="T0.25 B1" />
                  <Tags
                    wrap="left"
                    solid="true"
                    tags={example.categories.map((cat) => ({
                      name: cat.name,
                      link: cat.url
                    }))}
                  />
                </Layout.Column>
              )}
              {example.services?.length > 0 && (
                <Layout.Column style={{ width: '100%' }} margin={'T2'}>
                  <Text as="span" size={0.7} uppercase spacing={0.05} bold>
                    Services
                  </Text>
                  <Line style={{ width: '100%' }} margin="T0.25 B1" />
                  <Layout.Row style={{ flexWrap: 'wrap' }}>
                    {example.services.map((service, i) => {
                      return (
                        <Link
                          key={`metric-service-${i}`}
                          to={`/services/${service.name
                            .toLowerCase()
                            .replace(/ /g, '-')}`}
                        >
                          <ServiceLogo margin="0 0.5" sm logo={service.name} />
                        </Link>
                      )
                    })}
                  </Layout.Row>
                </Layout.Column>
              )}
            </div>
          )}
        </div>
      )}
    </ChatWrapper>
  )
}

ChatMessageResponse.propTypes = {
  role: PropTypes.string,
  children: PropTypes.node,
  example: PropTypes.object
}

const ChatBotPage = ({ location }) => {
  const {
    site: {
      siteMetadata: { algolia_index }
    }
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            algolia_index
          }
        }
      }
    `
  )

  const [submitted, setSubmitted] = useState(false)
  const [messages, setMessages] = useState([])
  const [results, setResults] = useState(false)
  const searchRef = useRef(null)
  const index = algoliaClient.initIndex(algolia_index)
  const mobileView = useMedia('desktop')

  const orbVariants = {
    big: {
      width: mobileView ? '250px' : '400px',
      height: mobileView ? '250px' : '400px',
      boxShadow: [
        '0',
        'inset 0 0 20px 15px rgba(117, 225, 178, 1)',
        'inset 0 0 120px 15px rgba(117, 225, 178, 1)',
        'inset 0 0 20px 15px rgba(117, 225, 178, 1)',
        '0'
      ],
      transition: {
        duration: 0.4,
        boxShadow: {
          duration: 1,
          repeat: Infinity,
          repeatType: 'mirror'
        }
      }
    },
    small: {
      width: mobileView ? '200px' : '250px',
      height: mobileView ? '200px' : '250px',
      boxShadow: [
        '0',
        'inset 0 0 20px 15px rgba(117, 225, 178, 1)',
        'inset 0 0 120px 15px rgba(117, 225, 178, 1)',
        'inset 0 0 20px 15px rgba(117, 225, 178, 1)',
        '0'
      ],
      transition: {
        duration: 0.4,
        boxShadow: {
          duration: 1,
          repeat: Infinity,
          repeatType: 'mirror'
        }
      }
    }
  }

  const searchVariants = {
    show: { marginTop: mobileView ? '1.5rem' : '2.5rem' },
    hide: {
      marginTop: '0.5rem',
      transition: {
        duration: 0.4
      }
    }
  }

  const headerVariants = {
    small: {
      fontSize: !mobileView ? '2.4rem' : '1.4rem',
      transition: {
        delay: 1,
        duration: 0.5
      }
    },
    big: {
      fontSize: !mobileView ? '3rem' : '1.8rem'
    }
  }

  async function retrieveObject(objectId) {
    if (objectId === null || objectId === '') {
      return null
    }
    try {
      const object = await index.getObject(objectId)
      return object
    } catch (error) {
      return error
    }
  }

  const chatWithGPT = useCallback(
    async (message) => {
      const chatJson = await fetch('https://chat.klipfolio.com/', {
        method: 'POST',
        body: JSON.stringify({
          message,
          context: messages
            .slice(0, 2)
            .map((v) => ({ role: v.role, content: v.content }))
        }),
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const chat = await chatJson.json()

      const searchResult = await retrieveObject(chat.objectID)

      setMessages([
        { role: 'user', content: message },
        { ...chat, example: searchResult },
        ...messages
      ])

      setResults(true)
      setSubmitted(false)
    },
    [setMessages, messages]
  )

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault()
      setSubmitted(true)
      setResults(false)
      searchRef.current.blur()
      const data = new FormData(event.target)
      const message = data.get('search')
      if (message) {
        chatWithGPT(message).then(() => {
          event.target.reset()
        })
      }
    },
    [chatWithGPT, setMessages, messages, setResults, setSubmitted]
  )

  return (
    <ChatLayout location={location}>
      <SEO
        location={location}
        title="AI Chatbot"
        description={`Get personalized insights and answers with our new chatbot. Combining ChatGPT and MetricHQ, our tool helps small business owners quickly find data-driven answers to their queries for smarter decision-making.`}
      />
      <Wrapper
        as={motion.div}
        initial={'show'}
        animate={results && 'hide'}
        variants={wrapperVariants}
      >
        <Container>
          <TextContainer
            as={motion.div}
            key={`textContainer-${mobileView}`}
            animate={{
              x: !mobileView && [-200, 0],
              y: mobileView && [200, 0],
              opacity: [0, 1]
            }}
            transition={{
              delay: 1.5,
              duration: 0.6,
              ease: 'backInOut'
            }}
          >
            {!mobileView && (
              <AnimatePresence>
                {!submitted && !results && (
                  <Text
                    as={motion.p}
                    variants={textVariants}
                    initial="show"
                    animate="show"
                    exit="hide"
                    size={1.3}
                    style={{
                      lineHeight: '2.25rem',
                      whiteSpace: 'initial'
                    }}
                    margin="B0.5"
                    color="white"
                  >
                    {`AI Metric Insights`}
                  </Text>
                )}
              </AnimatePresence>
            )}
            <ChatHeader
              h1
              as={motion.h1}
              color="white"
              variants={headerVariants}
              initial="big"
              animate={(submitted || results) && 'small'}
            >
              Make better decisions with the MetricHQ Metric Assistant
            </ChatHeader>

            <AnimatePresence>
              {!mobileView && !submitted && !results && (
                <SubHeading
                  variants={textVariants}
                  initial="show"
                  animate="show"
                  exit="hide"
                  as={motion.p}
                  style={{
                    lineHeight: '1.7',
                    whiteSpace: 'initial'
                  }}
                  color="white"
                >
                  {`Unlock the power of data-driven decision making with Klipfolio's AI-powered Metric Assistant. Our chatbot uses advanced machine learning algorithms to help you uncover the metrics that matter most to your business.`}
                </SubHeading>
              )}
            </AnimatePresence>

            <form action="#" onSubmit={handleSubmit}>
              <SearchBarWrapper
                as={motion.div}
                initial={'show'}
                animate={(submitted || results) && 'hide'}
                variants={searchVariants}
              >
                <SearchBar
                  ref={searchRef}
                  name={'search'}
                  placeholder="Ask me about Metrics"
                ></SearchBar>
                <LoadContainer>
                  <Spinner
                    as={motion.div}
                    variants={spinnerVariants}
                    initial="hide"
                    animate={submitted && !results ? 'show' : 'hide'}
                  />
                </LoadContainer>
              </SearchBarWrapper>
            </form>
          </TextContainer>
          <OrbContainer
            key={`orbContainer-${mobileView}`}
            as={motion.div}
            animate={{
              x: !mobileView && ['-100%', '-100%', '-100%', '0%'],
              y: mobileView && ['50%', '50%', '50%', '0%'],
              scale: mobileView ? [1.3, 1.3, 1, 1] : [1.8, 1.8, 1, 1]
            }}
            transition={{
              duration: 2,
              ease: 'backInOut'
            }}
          >
            <OrbWrapper>
              <Orb
                as={motion.div}
                variants={orbVariants}
                initial={'big'}
                animate={(submitted || results) && 'small'}
              >
                <CenterOrb
                  as={motion.div}
                  animate={{
                    scaleX: ['100%', '120%'],
                    scaleY: ['100%', '120%'],
                    transition: {
                      duration: 4,
                      repeat: Infinity,
                      repeatType: 'mirror'
                    }
                  }}
                >
                  <motion.img
                    variants={logoVariants}
                    initial="initial"
                    animate={submitted ? 'loading' : 'initial'}
                    style={{
                      filter: 'drop-shadow(0px 0px 36px rgba(0, 51, 34, 0.4)',
                      width: submitted || results ? '50px' : '100px'
                    }}
                    src={hqLogo}
                    alt="MetricHQ AI ChatBot by Klipfolio"
                  />
                </CenterOrb>
              </Orb>
            </OrbWrapper>
          </OrbContainer>
        </Container>
      </Wrapper>
      <ChatBox padding={messages.length ? true : false}>
        <AnimatePresence>
          {messages.map(({ content, role, example }, i) => (
            <React.Fragment key={`chat-${messages.length - i}`}>
              <ChatMessageResponse role={role} example={example}>
                {parseContent(content, example)}
              </ChatMessageResponse>
              {i % 2 !== 0 && <div></div>}
            </React.Fragment>
          ))}
        </AnimatePresence>
        {messages.length && (
          <>
            <div style={{ flex: 1 }}></div>
            <Text
              size={0.9}
              style={{
                marginLeft: !mobileView && '60px',
                paddingLeft: !mobileView && '2rem',
                opacity: 0.35
              }}
            >
              Disclaimer: I provide information related to metrics, KPIs, and
              dashboards. The information I provide should be used for
              informational purposes only, and is not a substitute for
              professional advice. Instructions relating to PowerMetrics or any
              specific product may be inaccurate.
            </Text>
          </>
        )}
      </ChatBox>
    </ChatLayout>
  )
}

ChatBotPage.propTypes = {
  location: PropTypes.any
}

export default ChatBotPage
